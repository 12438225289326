<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  
  <div  id="maincontent_container">
    <!-- <spinner v-if="loading" /> -->
    <submenu-account menu="po"></submenu-account>

    <div class="submenu-content">
    <div v-if ="loading">
        <spinner v-if="loading" />
    </div>
    <div v-else>
        <div>
          <div class="mb-3 row">
            <h2>Details of Invoice # {{invno}}</h2>
          </div>
        </div>

        <br>

        <div>
            <div class="mb-3 row">
                <div class="col-md-2">
                Invoice No.:
                </div>
                <div class="col-md-4">
                {{invno}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Customer No.:
                </div>
                <div class="col-md-4">
                {{custno}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Bill To:
                </div>
                <div class="col-md-4">
                    <div v-html="bill_to"></div>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Ship To:
                </div>
                <div class="col-md-4">
                    <div v-html="ship_to"></div>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Ship From:
                </div>
                <div class="col-md-4">
                {{branch}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Ship Date:
                </div>
                <div class="col-md-4">
                {{shipdate}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Ship Via:
                </div>
                <div class="col-md-4">
                {{shipvia}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                F.O.B.:
                </div>
                <div class="col-md-4">
                {{fob}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Terms:
                </div>
                <div class="col-md-4">
                {{terms}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Purchase Order #:
                </div>
                <div class="col-md-4">
                {{pono}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Order Date:
                </div>
                <div class="col-md-4">
                {{orddate}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Sales Person:
                </div>
                <div class="col-md-4">
                {{sales}}
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-2">
                Weight
                </div>
                <div class="col-md-4">
                {{weight}}
                </div>
            </div>
        </div>

        <br><br>

    <!-- table  -->
        <div class="tb">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Qty.Req</th>
                    <th scope="col">Shipped</th>
                    <th scope="col">Voided Qty</th>
                    <th scope="col">Item Number</th>
                    <th scope="col">Description</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Extended Prices</th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="(item,key) in items" :key="key">
                        <tr>
                            <td>{{item.ordqty}}</td>
                            <td>{{item.shipqty}}</td>
                            <td>{{item.voidqty}}</td>
                            <td>{{item.itmno}}</td>
                            <td>{{item.desc}}</td>
                            <td>{{formatPrice(item.pirce.toFixed(2))}}</td>
                            <td>{{formatPrice(item.extended_price.toFixed(2))}}</td>
                        </tr>  
                    </template>                       
                </tbody>
            </table>
        </div>
    <!-- table end  -->

    <!-- below table -->
        <div>
            <div class="mb-3 row justify-content-end">
                <div class="col-2">
                Non Taxable:
                </div>
                <div class="col-2">
                {{formatPrice(nontax.toFixed(2))}}
                </div>
            </div>

            <div class="mb-3 row justify-content-end">
                <div class="col-2">
                Taxable:
                </div>
                <div class="col-2">
                {{formatPrice(taxable.toFixed(2))}}
                </div>
            </div>

            <div class="mb-3 row justify-content-end">
                <div class="col-2">
                0.0% Sales Tax:
                </div>
                <div class="col-2">
                {{formatPrice(taxamt.toFixed(2))}}
                </div>
            </div>

            <div class="mb-3 row justify-content-end">
                <div class="col-2">
                Total:
                </div>
                <div class="col-2">
                {{formatPrice(total.toFixed(2))}}
                </div>
            </div>

        </div>
    <!-- below table end -->   
        
        <div class="lead text-danger mb-5">
            <div class="mb-3 row">
                <div class="span8" v-if="tracking_url"><a :href="tracking_url" target="blank">Tracking # {{tracking}}</a></div>
                <div class="span8" v-else>Tracking # {{tracking}}</div>
            </div> 
            <div class="row">
                <div class="span8">Remark:This is not an offical invoice.
                The offical invoice was sent to you via email from you Ma Labs Account Manager. </div>
            </div>  
        </div> 
  </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import submenuAccount from "../../components/nav/submenuAccount.vue";

import { onMounted, ref } from "vue";
// import { useRouter } from 'vue-router'
import { getAuthAPI } from "../../utils/axios-api";
import { formatPrice } from '../../utils/common';

export default {
  name: "OrderDetails",
  components: { Spinner, BreadcrumbPage, submenuAccount },
  props: ["invno"],
  setup(props, context) {
    const loading = ref(true)
    const error = ref("")
    const invno = ref(props.invno);
    const orddate = ref("")
    const custno = ref("")
    const bill_to = ref("")
    const ship_to = ref("")
    const shipdate = ref("")
    const branch = ref("")
    const shipvia = ref("")
    const tracking = ref("")
    const tracking_url = ref("")
    const fob = ref("")
    const pono = ref("")
    const terms = ref("")
    const sales = ref("")
    const weight = ref("")
    const nontax = ref("")
    const taxable = ref("")
    const taxamt =ref("")
    const total = ref("")
    const items = ref([])
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Order Status", link: "ShipmentTracking"})
    breadcrumb_links.value.push({text: "Invoice # " + invno.value, link: ""})

    var url = "/order/details/" + invno.value;
    getAuthAPI
      .get(url)
      .then((response) => {
        let ord = response.data.results
        orddate.value = ord.orddate
        shipdate.value = ord.shipdate
        custno.value = ord.custno
        bill_to.value = ord.bill_to
        ship_to.value = ord.ship_to
        branch.value = ord.branch
        shipvia.value = ord.shipvia
        tracking.value = ord.tracking_no
        tracking_url.value = ord.tracking_url
        fob.value = ord.fob
        pono.value = ord.pono
        terms.value = ord.terms
        sales.value = ord.sales
        weight.value = ord.weight
        nontax.value = ord.nontax
        taxable.value = ord.taxable
        taxamt.value = ord.taxamt
        total.value = nontax.value + taxable.value
        items.value = ord.order_items
        for (let item in items.value) {
            orders.value.push(
                {"ordqty":items.value[item].ordqty,
                "shipqty":items.value[item].shipqty,
                "voidqty":items.value[item].voidqty,
                "itmno":items.value[item].itmno,
                "desc":items.value[item].desc,
                "pirce":items.value[item].pirce,
                "extended_price":items.value[item].extended_price,
                "shipped":items.value[item].shipped
                }
            )         
        } 


      
        loading.value = false;
      })
      .catch((err) => {
        loading.value = false;
        error.value = err.message;
      });

    return { loading,invno,custno,orddate,shipdate,bill_to,ship_to,
    branch,shipvia,tracking,tracking_url,fob,pono,terms,sales,weight,nontax,
    taxable,taxamt,total,items, breadcrumb_links,formatPrice};
  },
};
</script>

<style scoped>
.submenu-content {
    float: left;
    /* width: calc(100% - 300px); */
    padding: 0 5%

}
.lead{
    color:black;
    font-size: 1.2em;
}
</style>
